import React from 'react'
import { Link } from 'gatsby'
import {
  Siblings,
  useProjectsContext,
  useContextualProjectsSiblings,
} from '../hooks/useAllProjects'
import { useBackgroundColor } from '../hooks/useBackgroundColor'
import { PrismicProject } from '../../graphql-types'
import { ProjectSlice } from './projectSlice'
import { SEO } from './seo'

const ProjectHeaderLinks = ({
  context,
  siblings,
}: {
  context: string
  siblings: Siblings
}) => {
  return siblings.next && siblings.previous ? (
    <div className="hidden lg:flex fixed z-10 h-full w-full">
      <Link
        to={`/projects/${siblings.previous}`}
        state={{ context }}
        data-state={JSON.stringify({ context })}
        className="w-1/2 h-full"
        style={{ cursor: `w-resize` }}
      ></Link>
      <Link
        to={`/projects/${siblings.next}`}
        state={{ context }}
        data-state={JSON.stringify({ context })}
        className="w-1/2 h-full"
        style={{ cursor: `e-resize` }}
      ></Link>
    </div>
  ) : null
}

const NextProjectLink = ({ context }: { context: string }) => {
  return (
    <Link
      to={`/projects/${context.toLowerCase()}`}
      className="relative flex items-center justify-center text-5xl text-center"
    >
      <span className="py-24 lg:px-24">
        <em className="block lg:inline">Return To:</em> {context}
      </span>
    </Link>
  )
}

const Project = ({ project }: { project: PrismicProject | undefined }) => {
  const data = project?.data,
    backgroundColor = data?.background_color,
    { context } = useProjectsContext(),
    siblings = useContextualProjectsSiblings()

  useBackgroundColor(backgroundColor)

  return (
    <div className="project min-h-screen">
      <SEO type="project" uid={project?.uid} data={data} />
      <ProjectHeaderLinks context={context} siblings={siblings} />
      <div className="pb-24 lg:py-24 px-0 sm:px-4">
        {data?.body?.map((slice, index, array) =>
          slice ? (
            <ProjectSlice
              key={slice.id}
              slice={slice}
              index={index}
              siblings={array.length}
            />
          ) : null
        )}
      </div>
      <NextProjectLink context={context} />
    </div>
  )
}

export default Project
export { Project }
